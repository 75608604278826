import React from 'react';
import styled from '@mui/system/styled';
import ContentModule from '@last-rev/component-library/dist/components/ContentModule';

export interface FooterProps {
  variant?: string;
  navigationItems?: any[];
}

const Footer = ({ navigationItems, variant }: FooterProps) => {
  return (
    <Root variant={variant}>
      {navigationItems?.map((content) => (
        <ContentModule key={content.id} {...content} />
      ))}
    </Root>
  );
};

const Root = styled('footer', {
  name: 'Footer',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => [styles.root]
})<{ variant?: string }>``;

export default Footer;
